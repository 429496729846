import {
    Button,
    Card,
    Checkbox,
    Input,
    Select,
    SelectItem, Table,
    TableBody,
    TableCell, TableColumn, TableHeader,
    TableRow,
    Tooltip
} from "@nextui-org/react";
import {useNavigate, useParams} from "react-router";
import {BackIcon} from "../../assets/icons/BackIcon";
import {AddIcon} from "../../assets/icons/AddIcon";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/authContext";
import axios from "axios";
import {AnchorIcon} from "../../assets/icons/AnchorIcon";
import {EditIcon} from "../../assets/icons/EditIcon";
import {DeleteIcon} from "../../assets/icons/DeleteIcon";

const EmployerDetails = () => {
    const headers = {'Content-Type': 'multipart/form-data'};
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const history = useNavigate();
    const [logoPreview, setLogoPreview] = useState(null);
    const [error, setError] = useState("");
    const [employerLogo, setEmployerLogo] = useState(null);
    const [background, setBackground] = useState(null);
    const [disableLogo, setDisableLogo] = useState(false);
    const [logoChange, setLogoChange] = useState(false);
    const [backgroundChange, setBackgroundChange] = useState(false);
    const [initialLogo, setInitialLogo] = useState(false);
    const [sendData, setSendData] = useState({});
    const [city, setCity] = useState(null);
    const [category, setCategory] = useState(null);
    const [branches, setBranches] = useState([]);
    const {filterCities, filterCategories, setFilterCategories, setFilterCities} = useContext(AuthContext);

    useEffect(() => {
        axios.get("https://backend.sorpa.com/properties/admin/list/").then(
            res => {
                let categories = []
                let cities = []
                res.data.sub_categories.map((item) => {
                    if (item.id !== 0) {
                        categories.push(item)
                    }
                })
                res.data.cities.map((item) => {
                    if (item.id !== 0) {
                        cities.push(item)
                    }
                })
                setFilterCategories(categories);
                setFilterCities(cities);
            }
        ).catch(
            err => {
                console.log(err.response?.data?.message);
            }
        )
    }, []);

    useEffect(() => {
        axios.get(`https://backend.sorpa.com/places/admin/${params.employer_id}/details/`).then(
            res => {
                setLoading(false);
                console.log(res.data);
                setSendData(res.data);
                setInitialLogo(res.data.logo_exists);
                setLogoChange(false);
                setCity(res.data.city);
                setCategory(res.data.category);
                setDisableLogo(!res.data.logo_exists);
                setLogoPreview(res.data.logo);
                setBranches(res.data.branch_set);
            }
        ).catch(
            err => {console.log(err)}
        )
    }, []);

    const setLogo = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoChange(true);
            setLogoPreview(URL.createObjectURL(file));
            setEmployerLogo(file);
        }
        event.preventDefault();
    }
    const submitData = () => {
        const formData = new FormData();
        formData.append("name", sendData.name);
        formData.append("logo_exists", sendData.logo_exists);
        formData.append("head_name", sendData.head_name);
        formData.append("phone_number", sendData.phone_number);
        formData.append("login", sendData.login);
        formData.append("password", sendData.password);
        formData.append("bin_number", sendData.bin_number);
        formData.append("account_number", sendData.account_number);
        formData.append("off_percent", sendData.off_percent);
        formData.append("city", sendData.city);
        formData.append("category", sendData.category);
        if (typeof sendData.agreement === 'object'){
            formData.append("agreement", sendData.agreement);
        }
        if (typeof sendData.background === 'object'){
            formData.append("background", sendData.background);
        }
        if (sendData.is_shown){
            formData.append("is_shown", true);
        }
        if (logoChange){
            formData.append("logo_change", logoChange);
            if(employerLogo){
                formData.append("logo", employerLogo);
            }else{
                if (initialLogo !== sendData.logo_exists){
                    formData.append("logo", null);
                }
            }
        }
        console.log(sendData);
        axios.post(`https://backend.sorpa.com/places/admin/${params.employer_id}/edit/`, formData, {headers: headers}).then(
            res => {
                console.log(res.data.message);
                history('/employers');
            }
        ).catch(
            err => {
                setError(err.response.data.message);
            }
        )
    }
    if (loading){
        return null
    }
    return (
        <div style={{
            color: "white",
            width: "100%",
            height: "100vh",
            overflow:"auto",
            boxSizing: "border-box",
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                background: "black",
                maxWidth: 800,
                height: "100%",
                marginTop: 32
            }}>
                <div onClick={() => {
                    history("/employers")
                }} style={{
                    color: "white",
                    width: 240,
                    padding: 0,
                    fontSize: 18,
                    background: "none",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    cursor: "pointer"
                }}><BackIcon/>Предприниматели
                </div>
                {/*кнопки*/}
                <div style={{marginTop: 16, display: "flex", gap: 16}}>
                    <Button
                        onClick={submitData}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "#BDFF00",
                            color: "black",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Редактировать</p>
                    </Button>
                    <Button
                        onClick={() => {
                            history("/employers")
                        }}
                        style={{
                            width: 240,
                            border: "1px solid #BDFF00",
                            background: "none",
                            color: "#BDFF00",
                            fontWeight: 600,
                            borderRadius: 16
                        }}
                    >
                        <p>Отмена</p>
                    </Button>
                </div>
                <Card style={{
                    width: "100%",
                    background: "#121212",
                    color: "white",
                    minHeight: "720px",
                    padding: 24,
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                    gap: 20
                }}>
                    {
                        error &&  <p style={{color:"red"}}>{error}</p>
                    }
                    <div style={{display: "flex", flexDirection: "row", gap: 20, alignItems: "center"}}>
                        <div>
                            {
                                logoPreview ?
                                    <div style={{
                                        width: 78,
                                        height: 78,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}>
                                        <img style={{
                                            borderRadius: 12,
                                            height: 64,
                                            width: 64,
                                            objectFit: "cover",
                                            background: "#2A2A2C"
                                        }} src={logoPreview}/>
                                        <label htmlFor={"logo-input"}><p style={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            marginTop: 4,
                                            textAlign: "center",
                                            cursor: "pointer"
                                        }}>Изменить</p></label>
                                    </div>
                                    :
                                    <label htmlFor={"logo-input"}>
                                        <div style={{
                                            width: 78,
                                            height: 78,
                                            background: "#2A2A2C",
                                            borderRadius: 12,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <AddIcon/>
                                        </div>
                                    </label>
                            }
                            <input accept={"image"} disabled={disableLogo} id={"logo-input"} type={"file"}
                                   style={{display: "none"}}
                                   onChange={(event) => {
                                       setLogo(event);
                                   }}
                            />
                        </div>
                        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                            <p>Логотип предприятия</p>
                            <Checkbox
                                isSelected={!sendData.logo_exists}
                                onChange={(value) => {
                                setDisableLogo(value.target.checked);
                                setError("");
                                setSendData({...sendData, logo_exists: !value.target.checked});
                                if(initialLogo !== !value.target.checked){
                                    setLogoChange(true);
                                }else{
                                    setLogoChange(false);
                                }
                                if (value.target.checked) {
                                    setLogoPreview(null);
                                }else{
                                    if (employerLogo){
                                        setLogoPreview(URL.createObjectURL(employerLogo));
                                    }else if (sendData.logo){
                                        setLogoPreview(sendData.logo);
                                    }
                                }
                            }}><p style={{color: "white"}}>Отсутствует</p></Checkbox>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="Наименование"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.name}
                            onChange={(event) => {
                                setSendData({...sendData, name: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Имя Владельца"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.head_name}
                            onChange={(event) => {
                                setSendData({...sendData, head_name: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{width: "50%", paddingRight: 5}}>
                        <Input
                            type={"tel"}
                            label="Контактный номер владельца"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.phone_number}
                            onChange={(event) => {
                                setSendData({...sendData, phone_number: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="Логин"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.login}
                            onChange={(event) => {
                                setSendData({...sendData, login: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Пароль"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.password}
                            onChange={(event) => {
                                setSendData({...sendData, password: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <Input
                            type={"text"}
                            label="БИН"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.bin_number}
                            onChange={(event) => {
                                setSendData({...sendData, bin_number: event.target.value});
                                setError("");
                            }}
                        />
                        <Input
                            type={"text"}
                            label="Номер счета"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.account_number}
                            onChange={(event) => {
                                setSendData({...sendData, account_number: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{width: "50%", paddingRight: 5}}>
                        <Input
                            type={"text"}
                            label="Процент скидки"
                            size={"sm"}
                            radius="lg"
                            style={{color: "white"}}
                            className={"input-text-color"}
                            classNames={{
                                label: "input-text-color",
                                input: "text-white/90",
                                innerWrapper: "input-text-color",
                                inputWrapper: "input-main",
                            }}
                            value={sendData.off_percent}
                            onChange={(event) => {
                                setSendData({...sendData, off_percent: event.target.value});
                                setError("");
                            }}
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                        <div style={{width: "100%"}}>
                            <Select
                                placeholder={"Выбрать город"}
                                defaultSelectedKeys={[city]}
                                onChange={(items) => {
                                    setCity(items.target.value);
                                    setSendData({...sendData, city: items.target.value});
                                    if (!items.target.value || items.target.value === "0") {
                                        setCity(null);
                                    }
                                }}
                                listboxProps={{
                                    itemClasses: {
                                        base: "wrapper-content"
                                    },
                                }}
                                popoverProps={{
                                    classNames: {
                                        content: "wrapper",
                                    },
                                }}
                                disallowEmptySelection={false}
                                color={"secondary"}
                                size={"sm"}
                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                classNames={{
                                    label: "text-white",
                                }}
                            >
                                {filterCities.map((city) => (
                                    <SelectItem key={city.id} value={city.name}>
                                        {city.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{width: "100%"}}>
                            <Select
                                placeholder={"Выбрать категорию"}
                                defaultSelectedKeys={[category]}
                                onChange={(items) => {
                                    setCategory(items.target.value);
                                    setSendData({...sendData, category: items.target.value});
                                    if (!items.target.value || items.target.value === "0") {
                                        setCategory(null);
                                    }
                                }}
                                listboxProps={{
                                    itemClasses: {
                                        base: "wrapper-content"
                                    },
                                }}
                                popoverProps={{
                                    classNames: {
                                        content: "wrapper",
                                    },
                                }}
                                disallowEmptySelection={false}
                                color={"secondary"}
                                size={"sm"}
                                style={{borderRadius: 16, background: "#2A2A2D", color: "white"}}
                                classNames={{
                                    label: "text-white",
                                }}
                            >
                                {filterCategories.map((category) => (
                                    <SelectItem key={category.id} value={category.name}>
                                        {category.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className={"flex gap-[16px] items-center"}>
                        <div>
                            <label htmlFor={"agreement_file"} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <AnchorIcon/> <p style={{marginLeft:10}}>Изменить договор</p>
                            </label>
                            <input id={'agreement_file'} style={{display:"none"}} type={"file"} onChange={(event) => {setSendData({...sendData, agreement: event.target.files[0]})}}/>
                        </div>
                        <a href={`https://backend.sorpa.com${sendData.background_image}`}>
                            <Button size={"sm"}>
                                Просмотреть договор
                            </Button>
                        </a>
                    </div>
                    <div className={"flex gap-[16px] items-center mb-[20px]"}>
                        <div>
                            <label htmlFor={"background"} style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <AnchorIcon/> <p style={{marginLeft:10}}>{sendData.background_image ? 'Изменить фон' : 'Прикрепить фон'}</p>
                            </label>
                            <input id={'background'} style={{display:"none"}} type={"file"} onChange={(event) => {setSendData({...sendData, background: event.target.files[0]})}}/>
                        </div>
                        <a href={`https://backend.sorpa.com${sendData.background_image}`}><Button size={"sm"}>
                            Просмотреть фон
                        </Button></a>
                    </div>
                    <Checkbox
                        defaultSelected={sendData.is_shown}
                        onChange={(value) => {
                            if (value.target.checked){
                                setSendData({...sendData, is_shown: true});
                            }else{
                                setSendData({...sendData, is_shown: false});
                            }
                            console.log(value.target.checked);
                        }}><p style={{color: "white"}}>Показать в приложении</p></Checkbox>
                </Card>
                <Card style={{
                    width: "100%",
                    background: "#121212",
                    color: "white",
                    height: "100%",
                    minHeight:400,
                    padding: 24,
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                    gap: 20
                }}>
                    <div>
                        <Button
                            startContent={<AddIcon/>}
                            onClick={() => {history(`/employers/${params.employer_id}/branch/add`)}}
                            style={{
                            height: "44px",
                            background: "none",
                            color: "white",
                            fontWeight: 600
                        }}
                        >
                            <p>Добавить филиал</p>
                        </Button>
                    </div>
                    <Table isStriped isHeaderSticky aria-label="Example static collection table"
                           classNames={{
                               base: 'table-wrapper',
                               table: "table",
                               wrapper: "base-wrapper"
                           }}
                    >
                        <TableHeader>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Доп название</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Показан в <br/> приложении?</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Город</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Сумма</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold"
                            }}>Скидка</TableColumn>
                            <TableColumn style={{
                                background: "#525255",
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>Действия</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {
                                branches.map((branch, index) => {
                                    return(
                                        <TableRow key={index}>
                                            <TableCell><p style={{
                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                margin: 0
                                            }}>{branch.name}</p></TableCell>
                                            <TableCell><p style={{
                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                margin: 0
                                            }}>{branch.is_shown ? "Да" : "Нет"}</p></TableCell>
                                            <TableCell><p style={{
                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                margin: 0
                                            }}>{branch.city}</p></TableCell>
                                            <TableCell><p style={{
                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                margin: 0
                                            }}>{branch.branch_sum}</p></TableCell>
                                            <TableCell><p style={{
                                                borderRight: "1px solid rgba(84, 84, 88, 0.65)",
                                                margin: 0
                                            }}>{branch.off_percent}</p></TableCell>
                                            <TableCell style={{width: 120}}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                    <Tooltip content="Редактировать">
                                                        <Button isIconOnly={true}
                                                                onClick={() => {
                                                                    history(`/employers/${params.employer_id}/branch/${branch.id}`)
                                                                }}
                                                                style={{background: "none"}}>
                                                            <EditIcon/>
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip content="Удалить">
                                                        <Button
                                                            onClick={() => {
                                                                axios.get(`https://backend.sorpa.com/places/admin/${params.employer_id}/branch/${branch.id}/delete/`).then(
                                                                    res => {
                                                                        setBranches(res.data)
                                                                    }
                                                                ).catch(err => {console.log(err)})
                                                            }}
                                                            isIconOnly={true}
                                                            style={{background: "none"}}>
                                                            <DeleteIcon/>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </Card>
            </div>
        </div>

    )
}

export default EmployerDetails;
