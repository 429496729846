import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import Employers from "./pages/Employers/Employers";
import Login from "./pages/Login/Login";
import EmployerDetails from "./pages/EmployerDetails/EmployerDetails";
import BranchDetails from "./pages/BranchDetails/BranchDetails";
import NewEmployer from "./pages/NewEmployer/NewEmployer";
import NewBranch from "./pages/NewBranch/NewBranch";
import Users from "./pages/Users/Users";
import Notifications from "./pages/Notifications/Notifications";
import News from "./pages/News/News";
import Promo from "./pages/Promo/Promo";
import Reports from "./pages/Reports/Reports";
import Statistics from "./pages/Statistics/Statistics";
import Club from "./pages/Club/Club";
import {ChangePositions} from "./pages/ChangePositions/ChangePositions";

const useRoutes = (isAuthenticated) => {

    if(isAuthenticated){
        if (localStorage.getItem("role") === "Бухгалтер"){
            return(
                <Routes>
                    <Route path="/reports" element={<Reports/>} />
                    <Route path="*" element={<Navigate to="/reports" />} />
                </Routes>
                )
        }
        return (
            <Routes>
                <Route path="/employers/change_positions" element={<ChangePositions/>} />
                <Route path="/employers" element={<Employers/>} />
                <Route path="/employers/add" element={<NewEmployer/>} />
                <Route path="/employers/:employer_id" element={<EmployerDetails/>} />
                <Route path="/employers/:employer_id/branch/:branch_id" element={<BranchDetails/>} />
                <Route path="/employers/:employer_id/branch/add" element={<NewBranch/>} />
                <Route path="/users" element={<Users/>} />
                <Route path="/notifications" element={<Notifications/>} />
                <Route path="/news" element={<News/>} />
                <Route path="/promo" element={<Promo/>} />
                <Route path="/reports" element={<Reports/>} />
                <Route path="/statistics" element={<Statistics/>} />
                <Route path="/users" element={<Users/>} />
                <Route path="/club" element={<Club/>} />
                <Route path="*" element={<Navigate to="/employers" />} />
            </Routes>
        );
    }
    return (
        <Routes>
            <Route path="/" element={<Login/>} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}

export default useRoutes;
